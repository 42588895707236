import { getAllUsers, getUserDetails, addUser, deleteUser } from "../api";
import { toast } from "react-toastify";

export const IS_LOADING = "IS_LOADING";
export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_TYPE_PA = "SET_USER_TYPE_PA";
export const SET_USER_TYPE_CU = "SET_USER_TYPE_CU";

//action creators
export const setIsLoading = bool => ({
  type: IS_LOADING,
  isLoading: bool
});
export const updateAllClients = users => ({
  type: UPDATE_ALL_USERS,
  allUsers: users
});
export const setUserDetails = user => ({
  type: SET_USER_DETAILS,
  userDetails: user
});
export const setIsUserTypePA = bool => ({
  type: SET_USER_TYPE_PA,
  isUserTypePA: bool
});
export const setIsUserTypeCU = bool => ({
  type: SET_USER_TYPE_CU,
  isUserTypeCU: bool
});

//async actions
export const asyncGetAllUsers = dispatch => {
  dispatch(setIsLoading(true));
  return getAllUsers().then(
    success => {
      dispatch(setIsLoading(false));
      dispatch(updateAllClients(success.body));
    },
    failure => {
      dispatch(setIsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetUserDetails = (username, history, dispatch) => {
  dispatch(setIsLoading(true));
  return getUserDetails(username).then(
    success => {
      dispatch(setIsLoading(false));
      if (success.body) {
        dispatch(setUserDetails(success.body));
      } else {
        history.push("/home");
      }
    },
    failure => {
      dispatch(setIsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncAddAnUser = (params, history, dispatch) => {
  dispatch(setIsLoading(true));
  return addUser(params).then(
    success => {
      dispatch(setIsLoading(false));
      toast.success("Added User Successfully!");
      if (success.body) {
        history.push("/home/users");
      }
    },
    failure => {
      dispatch(setIsLoading(false));
      toast.error("Failed to add an User");
      console.log(failure);
    }
  );
};

export const asyncUpdateAnUser = (params, history, dispatch) => {
  dispatch(setIsLoading(true));
  return addUser(params).then(
    success => {
      dispatch(setIsLoading(false));
      toast.success("Updated the User Successfully!");
      if (success.body) {
        history.push("/home/users");
      }
    },
    failure => {
      dispatch(setIsLoading(false));
      toast.error("Failed to update the User");
      console.log(failure);
    }
  );
};

export const asyncDeleteUser = (userId, dispatch) => {
  dispatch(setIsLoading(true));
  return deleteUser(userId).then(
    success => {
      if (success.body) {
        toast.success(success.body);
      }
      asyncGetAllUsers(dispatch);
    },
    failure => {}
  );
};
